export const Dictionary = {
    sessionStart: {
        URL: '/api/sessionStart',
        Method: 'POST'
    },
    getCategories: {
        URL: '/api/getCategories',
        Method: 'POST'
    },
    getProducts: {
        URL: '/api/getProducts',
        Method: 'POST'
    },
    getProduct: {
        URL: '/api/getProduct',
        Method: 'POST'
    },
    getProductsByCategory: {
        URL: '/api/getProductsByCategory',
        Method: 'POST'
    },
    getFeaturedProducts: {
        URL: '/api/getFeaturedProducts',
        Method: 'POST'
    },
    getBlogPosts: {
        URL: '/api/getBlogPosts',
        Method: 'POST'
    },
    getBlogPost: {
        URL: '/api/getBlogPost',
        Method: 'POST'
    },
    getSliderItems: {
        URL: '/api/getSliderItems',
        Method: 'POST'
    },
    getSpecialOffers: {
        URL: '/api/getSpecialOffers',
        Method: 'POST'
    },
    getCustomizables: {
        URL: '/api/getCustomizables',
        Method: 'POST'
    },

    //Cart
    getCartItems: {
        URL: '/api/cart/getItems',
        Method: 'POST'
    },
    addCartItem: {
        URL: '/api/cart/addItem',
        Method: 'POST'
    },
    removeCartItem: {
        URL: '/api/cart/removeItem',
        Method: 'POST'
    },
    clearCart: {
        URL: '/api/cart/clearItems',
        Method: 'POST'
    },

    //Checkout
    getPaymentTypes: {
        URL: '/api/checkout/getPaymentTypes',
        Method: 'POST'
    },
    sendVerificationCode: {
        URL: '/api/checkout/sendVerificationCode',
        Method: 'POST'
    },
    checkout: {
        URL: '/api/checkout/checkout',
        Method: 'POST'
    },

    //General Info
    getGeneralInfo: {
        URL: '/api/getGeneralInfo',
        Method: 'POST'
    },
    
    //Dynamic Pages
    getDynamicPages: {
        URL: '/api/getDynamicPages',
        Method: 'POST'
    },
    getDynamicPage: {
        URL: '/api/getDynamicPage',
        Method: 'POST'
    },

    getDeliveryTypes: {
        URL: '/api/getDeliveryTypes',
        Method: 'POST'
    },

    getFrontendStrings: {
        URL: '/api/getFrontendStrings',
        Method: 'POST'
    },

    getLanguages: {
        URL: '/api/getLanguages',
        Method: 'POST'
    },

    //Career
    getCareerPosts: {
        URL: '/api/career/getPosts',
        Method: 'POST'
    },
    getCareerPost: {
        URL: '/api/career/getPost',
        Method: 'POST'
    },
    submitCareerEntry: {
        URL: '/api/career/submitEntry',
        Method: 'POST'
    },
};
